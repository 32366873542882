.p-t-xs {
  padding-top: 5px;
}

.p-b-xs {
  padding-bottom: 5px;
}

.p-r-xs {
  padding-right: 5px;
}

.p-l-xs {
  padding-left: 5px;
}

.p-v-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-h-xs {
  padding-left: 5px;
  padding-right: 5px;
}

.p-h-sm {
  padding-right: 10px;
  padding-left: 10px;
}
.p-h-lg {
  padding-right: 30px;
  padding-left: 30px;
}

.p-t-sm {
  padding-top: 10px;
}

.p-b-sm {
  padding-bottom: 10px;
}

.p-r-sm {
  padding-right: 10px;
}

.p-l-sm {
  padding-left: 10px;
}

.p-v-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-b-md {
  padding-bottom: 20px;
}

.p-t-md {
  padding-top: 20px;
}

.p-r-md {
  padding-right: 20px;
}

.p-l-md {
  padding-left: 20px;
}

.p-v-md {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-h-md {
  padding-right: 20px;
  padding-left: 20px;
}

.p-t-lg {
  padding-top: 30px;
}

.p-b-lg {
  padding-bottom: 30px;
}

.p-l-lg {
  padding-left: 30px;
}

.p-r-lg {
  padding-right: 30px;
}

.m-auto {
  margin: auto;
}

.m-t-xs {
  margin-top: 5px;
}

.m-l-xs {
  margin-left: 5px;
}
.m-b-xs {
  margin-bottom: 5px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-v-sm {
  margin-bottom: 10px;
  margin-top: 10px;
}
.m-b-sm {
  margin-bottom: 10px;
}
.m-t-sm {
  margin-top: 10px;
}

.m-l-md {
  margin-left: 20px;
}
.m-r-md {
  margin-right: 20px;
}

.m-b-md {
  margin-bottom: 20px;
}

.m-t-md {
  margin-top: 20px;
}

.m-h-md {
  margin-left: 20px;
  margin-right: 20px;
}

.m-v-md {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-t-lg {
  margin-top: 30px;
}

.m-b-lg {
  margin-bottom: 30px;
}

.m-l-lg {
  margin-left: 30px;
}

.m-r-lg {
  margin-right: 30px;
}

.m-h-lg {
  margin-left: 30px;
  margin-right: 30px;
}

.text-center {
  text-align: center;
}

.b-b-grey {
  border-bottom: 1px solid #e0e0e0;
}

.b-t-grey {
  border-top: 1px solid #e0e0e0;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.width-100 {
  width: 100%;
}

.text-capitalize {
  text-transform: capitalize;
}

.rounded-shadow {
  .ant-select-selection {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
  }
}
